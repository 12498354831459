import { Link } from "@StarberryUtils"
import React,{useState,useEffect, useRef} from "react"
import { Container, Row, Col } from 'react-bootstrap';
import './Tab.scss';
import ReactMarkdown from "react-markdown"
import { AREA_GUIDES_PAGE_URL } from "@Components/common/site/constants";
import $ from "jquery"
const Tab = (props) => {
const [active, activeurl] = useState('')

const handleScroll = (e) => {

}
useEffect(() => {
  window.addEventListener("scroll", handleScroll)
  return () => {
    window.removeEventListener("scroll", handleScroll)
  }
}, [])

const scrollSection = (id) => {
  //console.log("testtt",id)
  $("html, body").animate({
      scrollTop: $("#" + id).offset().top - 200,
  },100)
}
 
  return (
    <div className="tab-content static-page-content">
      <Container>
        <Row>
          <Col md="3">
          <div className="property-quote tab-view">
            <ul>
              {props.Content && props.Content.length > 0 ?
                props.Content.map(item => {
                  var Title=item?.Tab_Title?.toLowerCase().replaceAll(' ', '_')
                  return (
                    <li className={active === Title ? 'active' : ''}><Link onClick={() => {
                      scrollSection(Title)
                      activeurl(Title)
                    }
                    }
                       to={AREA_GUIDES_PAGE_URL.alias+'/'+props.url+'#'+Title}>{item?.Tab_Title}</Link></li>
                  )
                }) : ''}
            </ul>
            </div>
          </Col>

          <Col md={{ span: 8, offset: 1 }}>
            {props.Content && props.Content.length > 0 ?
              props.Content.map(item => {
                var Title=item?.Tab_Title?.toLowerCase().replaceAll(' ', '_')
                return (
                  <div 
                  style={{scrollMarginTop:'120px'}}
                   className={props.fullwidth? "static-page-content-block fullwidth" : "static-page-content-block"} onScroll={(e) => activeurl(Title)} id={Title}>
                    <div className="rich-text-editer-block">
                    <ReactMarkdown source={item?.Tab_Content} allowDangerousHtml />
                    </div>
                    {item.Tab_Image?.url ?
                    <div className="image-blk">
                      <img src={item.Tab_Image?.url} alt={item.Tab_Image?.alternativeText} />
                    </div>
                    : null}
                  </div>
                )
              }) : ''}
          </Col>
        </Row>

      </Container>
    </div>
  )
}



export default Tab