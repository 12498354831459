import React from 'react';

const GoogleMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.6807526804846!2d-2.2443833!3d53.4741654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb18ed9a7326f%3A0x5aca7a7cdf9a2b74!2sBentley%20Hurst%20-%20Estate%20agents%20in%20Manchester!5e0!3m2!1sen!2sin!4v1714605790101!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;