import React from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ContentPageBlock from "@Components/Static/ContentPageBlock/ContentPageBlock"
import RelatedStories from '@Components/Static/RelatedStories/RelatedStories'
import { Container, Row, Col } from 'react-bootstrap';
import SubscribeBlock from '@Components/SearchResult/SubscribeBlock/SubscribeBlock'
import SocialShare from "@Components/Share/SocialShare";
import moment from "moment"
import BreadcrumbsBottom from "@Components/Static/BreadcrumbsBottom/BreadcrumbsHome"
const BlogDetails = (props) => {
  const GET_BLOG_DETAILS = gql`
  query GetBLog($URL: String!){	
    caseStudiesAndInsights(where:{URL: $URL}){
        Category
        Date
        Title
        Content
        Meta_Description
        Meta_Title
        Modules {
          ...on ComponentComponentAddPageContent {
            Add_Content {
              Video_Url
              Image {
                url
                alternativeText
              }
              Content_Block
            }
              
          }            
          }
        speak_with {
          Name
          Designation
          Phone
          Image {
            url
          }
        }
        Tile_Image {
          url
        }
    }
    rightBox {
      Content
      Right_Title
      Cta_Btn_Block_Right {
        Label
        all_menus {
          Alias
        }
      }
    }
  }
`;

  const { loading, data } = useQuery(GET_BLOG_DETAILS, {
    variables: { URL: props.slug }
  });
  if (loading) return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading"><div className="dot-pulse"></div></div>
      </Container>
    </div>);
  return (
    <Layout css={true} breadCrumPage={data?.caseStudiesAndInsights[0].Title} class="static" breadCrumAlias={'blog'} type="details-page">
      {data?.caseStudiesAndInsights.map((Page, i) => {
        return (
          <>
            <SEO title={Page ? Page.Title : ''} description={'Read about '+Page.Title+' here, Contact Bentley Hurst estate agents for all your property needs.'} />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template`
              }}

            />
<Helmet>
<script type="application/ld+json">{`{
"@context": "http://schema.org",
"@type": "NewsArticle",
"mainEntityOfPage": {
"@type": "WebPage",
"@id": "https://www.bentleyhurst.co.uk/about-us/blog/${props.slug}"
},
"headline": "${Page.Title}",
"image": [
"${Page.Tile_Image.url}"
],
"datePublished": "${moment(Page.Date).format('Do MMMM YYYY')}",
"dateModified": "${moment(Page.Date).format('Do MMMM YYYY')}",
"author": {
"@type": "Person",
"name": "Person name"
},
"publisher": {
"@type": "Organization",
"name": "Bentley Hurst - Estate Agents in Manchester",
"logo": {
"@type": "ImageObject",
"url": "https://www.bentleyhurst.co.uk/images/mail-logo.png"
}
},
"description": "Read about ${Page.Title} here, Contact Bentley Hurst estate agents for all your property needs."
 }`}</script>
 </Helmet>
            <Container>
              <Row>
                <Col md="8">
                  <div className="blog-header">
                    <h1>{Page.Title}</h1>
                    <div className="blog-detail">
                      <span className="date">{moment(Page.Date).format('Do MMMM YYYY')}</span> <span className="dot">.</span>
                      <span className="category">{Page.Category}</span> <span className="dot">.</span>
                      <SocialShare Title="Share with a Friend" />

                    </div>
                  </div>
                </Col>
                <Col md="4"></Col>
              </Row>
            </Container>

            {Page.Modules.map((Module, i) => {
              return (
                <div key={i}>
                  {Module.Add_Content !== null &&
                    <ContentPageBlock Content={Module?.Add_Content} Right_Bar={data?.rightBox} Speak_With={Page.speak_with} />
                  }
                </div>
              )
            })}
            <RelatedStories Title={'Similar Guides & Insight'} GuideandInsights="true" />
            <SubscribeBlock />
            {/* <BreadcrumbsBottom Page={Page.Title} class="static" alias={'blog'} type="details-page" /> */}
          </>
        )
      })}
    </Layout>
  );
}
export default BlogDetails