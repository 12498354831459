import React, { useState, useRef } from "react"
import { Container, Row, Col } from 'react-bootstrap';
// Style
import './StartMarketing.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { isMobile } from "react-device-detect";
const StartMarketing = (props) => {
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }
  const [step, setStep] = useState(0);

  const next = () => {
    setStep(step + 1)
    executeScroll()
  }

  const previous = () => {
    if (step === 0) {
      window.scroll(0, 0)
    }
    else {
      setStep(step - 1)
      executeScroll()
    }

  }

  const [isPlaying, setPlaying] = useState(false);

  const [videoindex, setVideoindex] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const playVideo = (video_id) => {
    setVideoindex(video_id)
    setShowVideo(true)
  }
  const trackerVideo = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

  return (
    <div className="technology-section">
      <div id="scrol-fix-top" ref={myRef}></div>
      {isMobile &&
        <Container>
          {props.Blocks.map((item, index) => {
            if (item?.Embed_Video_URL) {
              var videoid = getVideoId(item?.Embed_Video_URL);
            }
            return (
              <Row className="align-items-lg-center start-marketting"
              >

                <Col lg="6" className="order-lg-2 img-zoom">
                  <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true} delay={500}>
                    <div className="technology-video">
                      {item.Video_Ask ?
                        <iframe width='853' height='480' src={item.Embed_Video_URL} frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                        :
                        <>
                          {showVideo && videoindex === index && <div onClick={(e) => { setShowVideo(false) }} className="close-iframe-btn"></div>}
                          {showVideo && videoindex === index &&
                            <YouTube
                              video={videoid?.id}
                              autoplay
                              onEnd={e => { setShowVideo(false) }}
                              modestBranding={1}
                              onPlaying={trackerVideo(item.Title)}
                              showRelatedVideos={false}
                              showInfo={false}
                              annotations={false}

                            />
                          }
                          <picture>
                            <source media="(min-width:992px)" srcset={item?.Image?.url} />
                            <source media="(min-width:768px)" srcset={item?.Image?.url} />
                            <img className="" src={item?.Image?.url} alt={item?.Image?.alternativeText} />
                          </picture>

                          {showVideo && videoindex === index ? null : item.Embed_Video_URL ?
                            <>
                              <strong href="" className="play-btn" onClick={(e) => { playVideo(index) }}><i className="icon-play"></i></strong>

                            </>

                            : ''}
                        </>}
                    </div>
                  </ScrollAnimation>
                </Col>
                <Col lg="6" className="order-lg-1" >
                  <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                    <div className="technology-content test">
                      <h2>{item.Title}</h2>
                      <img className="d-block d-md-none mb-3" src={item?.Image?.url} alt={item?.Image?.alternativeText} />
                      <div className="rich-text-editer-block">
                        <ReactMarkdown source={item.Content} allowDangerousHtml />
                      </div>
                      {item?.Add_Start_Button?.URL?.URL && item?.Add_Start_Button?.Label &&
                        <GetURL label={item?.Add_Start_Button?.Label} alias={item?.Add_Start_Button?.URL?.URL} class={'btn btn-primary'} />
                      }
                      {/* <Link to={'/' + item?.Add_Start_Button?.URL?.URL} className="btn btn-primary">{}</Link> */}
                      <div className="next-back-btn">
                        <a
                          onClick={previous}
                        // to={index === 0 ? '/market-your-property#' : '/market-your-property#' + props.Blocks[index - 1]?.Add_Scroll_Button?.Scroll_Text}
                        ><span className="mr-2"><i className="up-icon"></i></span> Back to the Top</a>
                        {index === props.Blocks?.length - 1 ? null :
                          <a
                            onClick={next}
                          // to={'/market-your-property#' + props.Blocks[index + 1]?.Add_Scroll_Button?.Scroll_Text}
                          >{item?.Add_Scroll_Button?.Label} <span className="ml-2"><i className="down-icon"></i></span></a>
                        }
                      </div>
                    </div>
                  </ScrollAnimation>
                </Col>
                <PlayVideo
                  isOpen={isPlaying}
                  isCloseFunction={setPlaying}
                  videoId={item.Embed_Video_URL?.replace("https://www.youtube.com/watch?v=", "")}
                  isAutoPlay={1}
                />
              </Row>
            )
          })}

        </Container>
      }
      {!isMobile &&
        <Container>
          {props.Blocks.map((item, index) => {
            if (item?.Embed_Video_URL) {
              var videoid = getVideoId(item?.Embed_Video_URL);
            }
            return (
              index === step ?
                <Row className="align-items-lg-center start-marketting"
                // id={item?.Add_Scroll_Button?.Scroll_Text}
                >

                  <Col lg="6" className="order-lg-2 img-zoom">
                    {/* {item?.Embed_Video_URL} */}
                    <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true} delay={500}>
                      <div className="technology-video">
                        {item.Video_Ask ?
                          <iframe width='853' height='480' src={item.Embed_Video_URL} frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                          :
                          <>
                            {showVideo && videoindex === index && <div onClick={(e) => { setShowVideo(false) }} className="close-iframe-btn"></div>}
                            {showVideo && videoindex === index &&
                              <YouTube
                                video={videoid?.id}
                                autoplay
                                onEnd={e => { setShowVideo(false) }}
                                modestBranding={1}
                                onPlaying={trackerVideo(item.Title)}
                                showRelatedVideos={false}
                                showInfo={false}
                                annotations={false}

                              />
                            }


                            <picture>
                              <source media="(min-width:992px)" srcset={item?.Image?.url} />
                              <source media="(min-width:768px)" srcset={item?.Image?.url} />
                              <img className="d-none d-md-block" src={item?.Image?.url} alt={item?.Image?.alternativeText} />
                            </picture>

                            {showVideo && videoindex === index ? null : item.Embed_Video_URL ?
                              <>
                                <strong href="" className="play-btn" onClick={(e) => { playVideo(index) }}><i className="icon-play"></i></strong>

                              </>

                              : ''}
                          </>}
                      </div>
                    </ScrollAnimation>
                  </Col>
                  <Col lg="6" className="order-lg-1" >
                    <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                      <div className="technology-content">
                        <h2>{item.Title}</h2>
                        <div className="rich-text-editer-block">
                          <ReactMarkdown source={item.Content} allowDangerousHtml />
                        </div>
                        {item?.Add_Start_Button?.URL?.URL && item?.Add_Start_Button?.Label &&
                          <GetURL label={item?.Add_Start_Button?.Label} alias={item?.Add_Start_Button?.URL?.URL} class={'btn btn-primary'} />
                        }
                        {/* <Link to={'/' + item?.Add_Start_Button?.URL?.URL} className="btn btn-primary">{}</Link> */}
                        <div className="next-back-btn">
                          <a
                            onClick={previous}
                          // to={index === 0 ? '/market-your-property#' : '/market-your-property#' + props.Blocks[index - 1]?.Add_Scroll_Button?.Scroll_Text}
                          ><span className="mr-2"><i className="up-icon"></i></span> Back to the Top</a>
                          {index === props.Blocks?.length - 1 ? null :
                            <a
                              onClick={next}
                            // to={'/market-your-property#' + props.Blocks[index + 1]?.Add_Scroll_Button?.Scroll_Text}
                            >{item?.Add_Scroll_Button?.Label} <span className="ml-2"><i className="down-icon"></i></span></a>
                          }
                        </div>
                      </div>
                    </ScrollAnimation>
                  </Col>
                  <PlayVideo
                    isOpen={isPlaying}
                    isCloseFunction={setPlaying}
                    videoId={item.Embed_Video_URL?.replace("https://www.youtube.com/watch?v=", "")}
                    isAutoPlay={1}
                  />
                </Row>

                : null

            )
          })}

        </Container>
      }
    </div>
  )
}

export default StartMarketing