import React, {useState,useEffect} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import axios from "axios"
// import Img from "gatsby-image";
import SlickSlider from 'react-slick';
/**
 * Components
 */

import { Star } from '@Components/icon/icon';
import ReactReadMoreReadLess from "react-read-more-read-less"
/**
 * Assets
 */

import './styles/_index.scss';

const Reviews = (props) => {
    const [testimonials, setTestimonials] = useState([])
    const [isClient, setClient] = useState(false);

    const strapiconfig = {
      headers: {
          Authorization:
              `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
    }

    const getitems = async url => {
      try {
        const { data } = await axios.get(url, strapiconfig)// could be from env files
        setTestimonials(data)
      } catch (error) {
        console.error(error)
      }
    }
    useEffect(() => {

      let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
      getitems(url);
      setClient(true);
    }, []);

    const latestReviewsScore = useStaticQuery(graphql`
    query {
        allStrapiGoogleReviewReviews {
            edges {
                node {
                    id
                    starRating
                }
            },
            pageInfo {
                totalCount
            }
           }
          }
   `)

    const reviews = testimonials
    const reviewsCount = latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount;
    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};
    let sumOfScores = 0;

    reviews.map((review) => {
        const reviewStringValue = review.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
        return(sumOfScores)
    })
    
    return (
        <div className="static-reviews"  data-aos="fade-up">
            <Container>
                <h2>{props.Title}</h2> 
                {props.isPage ? <>
                    {testimonials.map((item) => (
                    <>
                    {(item.comment && item.comment !== undefined) &&
                    <div className="review single">
                        <div className="review__inner">
                            <div className="stars">
                                {/* {item.starRating} */}
                                {item.starRating === 'FOUR' && (item.comment && item.comment !== undefined) ?
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>
                                :
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>}
                            </div>
                            <div className="google-content">
                            {item?.comment && (
                            <ReactReadMoreReadLess
                                charLimit={100}
                                readMoreText={"more"}
                                readLessText={"less"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {item?.comment}
                            </ReactReadMoreReadLess>
                            )}
                            </div>
                            <Row className="flex-nowrap google-top">
                                <Col xs="auto">
                                    <div className="icon">
                                        <img src={item?.reviewer?.profilePhotoUrl} />
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    <h5>{item.reviewer.displayName}</h5>
                                    {/* <small>{item.createTime}</small> */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    }
                    </>
                    ))}
                    </>
                :
                <SlickSlider
                    arrows={false}
                    dots={false}
                    infinite={false}
                    speed={500}
                    slidesToShow={3}
                    slidesToScroll={1}
                    responsive={[{
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            dots: true
                        }
                    }]}
                >
                    {testimonials.slice(0, 8).map((item) => (
                    <>
                    {(item.comment && item.comment !== undefined) &&
                    <div className="review">
                        <div className="review__inner">
                            <div className="stars">
                                {/* {item.starRating} */}
                                {item.starRating === 'FOUR' && (item.comment && item.comment !== undefined)  ?
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>
                                :
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>}
                            </div>
                            <div className="google-content">
                            <ReactReadMoreReadLess
                                charLimit={100}
                                readMoreText={"more"}
                                readLessText={"less"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {item?.comment}
                            </ReactReadMoreReadLess>
                            </div>
                            <Row className="flex-nowrap google-top">
                                <Col xs="auto">
                                    <div className="icon">
                                        <img src={item.reviewer.profilePhotoUrl} />
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    <h5>{item.reviewer.displayName}</h5>
                                    <small>{item.createTime}</small>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    }
                    </>
                  ))}
                </SlickSlider>
                }
            </Container>
        </div>
    )
}

export default Reviews

