import React from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { Container } from 'react-bootstrap';
import StaticContactBlock from '@Components/Static/StaticContactBlock/StaticContactBlock'
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import Banner from "@Components/Home/Banner/Banner"
import PropertyMap from "@Components/PropertyDetails/PropertyMap/PropertyMap"
import FeaturedPropery from "@Components/PropertyDetails/FeaturedPropery/FeaturedPropery"
import ContentBlockText from "@Components/Static/ContentBlockText/ContentBlockText"
import Tab from "@Components/Static/Tab/Tab"
import BreadcrumbsBottom from "@Components/Static/BreadcrumbsBottom/BreadcrumbsHome"
const AreaGuideDetails = (props) => {
  const GET_TEAM_MEMBER = gql`
  query GetAreaGuide($URL: String!){	
    areaGuides(where:{URL: $URL}){
      id
      imagetransforms
        Banner_Content
        Banner_Title
        Embed_Video_URL
        Intro_Content
        Meta_Description
        Meta_Title
        Longitude
        Latitude
        Review_Show
        Title
        URL
        Tabs {
          Tab_Content
          Tab_Title
          Tab_Image {
            url
            alternativeText
          }
        }
        Banner_Image {
            url
            alternativeText
          }
        Banner_Cta1 {
          CTA_Label
          CTA_URL {
            Alias
          }
        }
        Banner_Cta2 {
          CTA_Label
          CTA_URL {
            Alias
          }
        }

    }
  }
`;
  const { loading, data } = useQuery(GET_TEAM_MEMBER, {
    variables: { URL: props.slug }
  });
  if (loading) return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading"><div className="dot-pulse"></div></div>
      </Container>
    </div>);
  return (
    <Layout  breadCrumPage={data?.areaGuides[0]?.Title} breadCrumAlias={'areas-we-cover'} type="details-page">
      {data?.areaGuides.map((Page, i) => {
        return (
          <>
            <SEO title={Page ? Page.Title+ ' Area Guide ' : ''} description={'Discover the beautiful spots in the '+Page.Title+' area, and the intersting thinks to do around '+Page.Title+' with the Bentley Hurst Manchester area guide.'} />
            <Helmet
              bodyAttributes={{
                class: `templates-team-details-template homepage`
              }}
            />


            <Banner areaGuide={true} Title={Page.Banner_Title} Banner_Content={Page.Banner_Content} Banner_CTA_1_Label={Page.Banner_Cta1?.CTA_Label} Banner_CTA_1_URL={Page.Banner_Cta1.CTA_URL} Banner_CTA_2_Label={Page.Banner_Cta2.CTA_Label} Banner_CTA_2_URL={Page.Banner_Cta2.CTA_URL}
              Banner_Image={Page?.Banner_Image}
              id={Page.id}
              imagetransforms={Page.imagetransforms}
              Embed_Video_URL={Page.Embed_Video_URL} Show_Review={Page.Review_Show} />

            <ContentBlockText
              isTeam={false}
              data={Page.Intro_Content}
              fullwidth={true}
            />
            <Tab fullwidth={true} Content={Page?.Tabs} url={Page?.URL} />
            <PropertyMap property_map_lat={Page?.Latitude} property_map_lng={Page?.Longitude} />
            <FeaturedPropery
              Title={'Featured Properties in ' + Page.Title + ', London'}
              area={Page.Title}
            />
            <StaticContactBlock />
            {/* <BreadcrumbsBottom Page={Page.Title} alias={'areas-we-cover'} type="details-page" /> */}
          </>
        )
      })}
    </Layout>
  );
}
export default AreaGuideDetails