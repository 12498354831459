import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios";
import wordsToNumbers from 'words-to-numbers';
import GoogleReviews from "../../../images/google-reviews1.svg";
// Style
import './BlockGroupOne.scss';
import GetURL from '@Components/common/site/get-url';

const BlockGroupOne = (props) => {
  const [testimonials, setTestimonials] = useState([])
  const [hoverclass, setHoverclass] = useState(false)
  // const [isClient, setClient] = useState(false);

  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
}
const getitems = async url => {
  try {
    const { data } = await axios.get(url, strapiconfig)// could be from env files
    setTestimonials(data)
  } catch (error) {
    // cache it if fail/error;
    console.error(error)
  }
}
useEffect(() => {

  let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
  getitems(url);
  // setClient(true);
}, [])
var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;

  const getOnScroll = () => {
    // console.log('okk')
  }

  const data = useStaticQuery(graphql`
  query{

    strapiMiniDashboard {
      Bottom_Count
      Bottom_Count_Text
      CTA_1_Label
      CTA_1_URL {
        Alias
      }
      Content
      Top_Count
      Top_Count_Text
      Image {
        internal {
          description
        }
      }
    }
    allStrapiGoogleReviewReviews {
      edges {
          node {
              id
              starRating
          }
      },
      pageInfo {
          totalCount
      }
  }
}
  `);
  return (
    <div className="block-row mini">
      <div className="d-lg-flex" onWheel={getOnScroll}>
        <ScrollAnimation animateIn='slideIn' className="block-row-left red-bg-color">
          <div className="google-rating-block">
            <div className="google-rating">
              <div>
                <img src={GoogleReviews} alt="Google Reviews Bentley Hurst"/>
              </div>
              <p>Rated <strong>{rating_avg ? parseFloat(rating_avg.toFixed(1)) : ''}/5</strong> from {testimonial_count ? testimonial_count : ''} Reviews</p>
              <Link to="/about-us/reviews" className="link-text"> <span className={hoverclass ? "red btn-arrow" : "btn-arrow"} onMouseEnter={()=>setHoverclass(true)} onMouseLeave={()=>setHoverclass(false)}><i className="icon-arrow"></i></span>Customer Reviews</Link>

            </div>
            {/* <strong className="day-text">{data.strapiMiniDashboard?.Top_Count}</strong>
            <div className="rich-text-editer-block">
              <ReactMarkdown source={data.strapiMiniDashboard?.Top_Count_Text} allowDangerousHtml />
            </div> */}
          </div>
          <div className="block-row-bottom">
            <strong className="day-text">{data.strapiMiniDashboard?.Bottom_Count}</strong>
            <Link to="/contact-manchester-estate-agents/" className="link-text"> <span className={hoverclass ? "red btn-arrow" : "btn-arrow"} onMouseEnter={()=>setHoverclass(true)} onMouseLeave={()=>setHoverclass(false)}><i className="icon-arrow"></i></span>Contact us</Link>

            <div className="rich-text-editer-block">
              <ReactMarkdown source={data.strapiMiniDashboard?.Bottom_Count_Text} allowDangerousHtml />
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn='slideIn' className="block-row-right">

          <div className='big-image-bg' style={{ backgroundImage: `url(${data.strapiMiniDashboard?.Image?.internal?.description.replace("File ", "").replace('"', '').replace('"', '')})` }} />

          <div className="block-row-content rich-text-editer-block">
            <ReactMarkdown source={data.strapiMiniDashboard?.Content} allowDangerousHtml />
          </div>
          {data.strapiMiniDashboard?.CTA_1_Label ?
            <GetURL isLinkArrow="true" label={data.strapiMiniDashboard?.CTA_1_Label} alias={data.strapiMiniDashboard?.CTA_1_URL?.Alias} class={'link-text'} />
            : ''}


        </ScrollAnimation>

      </div>


    </div>
  )
}



export default BlockGroupOne