import { Link } from "@StarberryUtils"
import React, {  useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import SubscribeBlock from '@Components/SearchResult/SubscribeBlock/SubscribeBlock'
import './RelatedStories.scss';
import ReactMarkdown from "react-markdown"
import {  BLOGS_PAGE_URL } from "@Components/common/site/constants";
import { useStaticQuery, graphql } from "gatsby";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import { Helmet } from "react-helmet";

const RelatedStories = (props) => {
  const [hoverclass,setHoverclass] = useState(false)

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
    caseStudiesAndInsights(where: {Publish:true}, sort: "Date:desc") {
                Category
                Content
                Date
                Title
                URL
                Modules {
                  ... on GLSTRAPI_ComponentComponentAddPageContent {
                    id
                    Add_Content {
                      Content_Block
                      Video_Url
                      Image {
                        alternativeText
                        url
                      }
                    }
                  }
                }
                Tile_Image {
                  alternativeText
                  url
                }
                id
                imagetransforms
                Publish
       }
      }
      
  }
`);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    adaptiveHeight: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        }
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        }
      }
    ]
  };

  const guideandinsights = []
  // guideandinsights.push(data.allStrapiAreaGuide.edges.slice(0, 2))
  guideandinsights.push(data.glstrapi.caseStudiesAndInsights.slice(0, 4))

  return (
    props.Page ? <><div className="related-silder-block page">
      <Container>
       <Helmet>
  <script type="application/ld+json">{`{
  "@context":"https://schema.org",
  "@type":"ItemList",
  "itemListElement":[
    {
      "@type":"ListItem",
      "position":1,
      "url":"https://www.bentleyhurst.co.uk/about-us/blog/updated-guidance-understanding-the-possession-action-process"
    },
 {
      "@type":"ListItem",
      "position":2,
      "url":"https://www.bentleyhurst.co.uk/about-us/blog/new-bill-aims-to-help-uk-government-achieve-its-energy-efficiency-commitments"
    },
 {
      "@type":"ListItem",
      "position":3,
      "url":"https://www.bentleyhurst.co.uk/about-us/blog/majority-of-estate-agencies-will-ask-customers-and-staff-to-wear-face-masks"
    },
 {
      "@type":"ListItem",
      "position":4,
      "url":"https://www.bentleyhurst.co.uk/about-us/blog/housing-market-shows-no-signs-of-cooling-off"
    },
 {
      "@type":"ListItem",
      "position":5,
      "url":"https://www.bentleyhurst.co.uk/about-us/blog/updated-guidance-new-forms-for-secure-and-assured-tenancies-released"
    },
 {
      "@type":"ListItem",
      "position":6,
      "url":"https://www.bentleyhurst.co.uk/about-us/blog/evictions-ban-updated-guidance-relating-to-latest-possession-actions"
    }
]
    }`}</script>
 </Helmet>
        <Row>
          <Col xl="12">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <h1>{props.Title}</h1>
              <div className="News-content rich-text-editer-block">
                <ReactMarkdown source={props.Content} allowDangerousHtml />
              </div>
            </ScrollAnimation>
          </Col>
          <Col xl="12">
          <ScrollAnimation className="case-page-blk" animateIn='fadeIn' animateOnce={true} delay={300}>
            <div className="related-silder slick-pagination">
              <div className="related-silder-wrap blog-wrap">
                {data.glstrapi.caseStudiesAndInsights.map((node, index) => {
                  let processedImages = JSON.stringify({});
                  if (node?.imagetransforms?.Image_Transforms) {
                      processedImages = node.imagetransforms.Image_Transforms;
                  }
                  return (
                      <div className="customer-col img-zoom blog-bx">
                        <div className="customer-img">
                          <Link to={node.URL}>
                          
                          <ImageTransform imagesources={node?.Tile_Image?.url} renderer="srcSet" imagename='case-studies-and-insights.Image.tailbig'
                                     attr={{ alt: node.Tile_Image?.alternativeText, className: ''}}
                                     imagetransformresult={processedImages} id={node.id} />
                                    
                          
                          </Link>
                          <span className="img-tag">{node.Category}</span>
                        </div>
                        <div className="customers-info">
                        {index === 0 ?
                          <Link to={node.URL}><h3>{node.Title}</h3></Link>
                          :
                          <h3><Link to={node.URL}>{node.Title}</Link></h3> }
                          <span>{node.Date}</span>
                          {index === 0 ? <div className="contetn">
                          <div className="rich-text-editer-block">
                           <ReactMarkdown source={node?.Content} allowDangerousHtml />
                           </div>
                           <div className="view-more-wrap">
                            <Link to={node.URL} className="link-text"> <span className={hoverclass ? "red btn-arrow" : "btn-arrow"} onMouseEnter={() => setHoverclass(true)} onMouseLeave={() => setHoverclass(false)}><i className="icon-arrow"></i></span>View Article</Link>
                          </div>
                           </div>
                            : null}

                        </div>
                      </div>
                  )
                })
                }
              </div>
            </div>
          </ScrollAnimation>
                </Col>
        </Row>
      </Container>
    </div>
    <SubscribeBlock />
  </> :
      <div className="related-silder-block">

        <Container>
          <Row>
            <Col xl="12">
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <h2>{props.Title}</h2>
              </ScrollAnimation>
            </Col>

            <Col xl="12">
              <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300}>
                <div className="related-silder slick-pagination blog-page-blk">
                  <div className="d-flex25 related-silder-wrap">
                  {props.GuideandInsights ? 
                    <Slider {...settings}>
                      

                        {guideandinsights[0].map((node, index) => {
                          return (
                            <div className="customer-col img-zoom single-r">
                              <div className="customer-img">
                                <Link href={BLOGS_PAGE_URL.alias+'/'+node.URL}><img src={node.Tile_Image?.url} alt={node.Tile_Image?.alternativeText} /></Link>
                                <span className="img-tag">{node.Category}</span>
                              </div>
                              <div className="customers-info">
                                <h3><Link href={BLOGS_PAGE_URL.alias+'/'+node.URL}>{node.Title}</Link></h3>
                                <span>{node.Date}</span>
                              </div>
                            </div>
                          )
                        })
                        }
                    </Slider>
                    : 
                    <Slider {...settings}>
                        {data.glstrapi.caseStudiesAndInsights.slice(0, 4).map((node, index) => {
                          return (
                            <div className="customer-col img-zoom">
                              <div className="customer-img">
                                <Link href={node.URL}><img src={node.Tile_Image?.url} alt={node.Tile_Image?.alternativeText} /></Link>
                                <span className="img-tag">{node.Category}</span>
                              </div>
                              <div className="customers-info">
                                <h3><Link href={node.URL}>{node.Title}</Link></h3>
                                <span>{node.Date}</span>
                              </div>
                            </div>
                          )
                        })
                        }
                    </Slider>
                    }
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default RelatedStories